<template>
  <div class="tour">
    <!-- Step 1 modal -->
    <b-modal
      content-class="steps"
      ref="tour"
      id="tour"
      no-close-on-backdrop
      centered
    >
      <div v-if="step == 1">
        <div class="title">{{$t('tour.How to create your first campaign')}}!</div>
        <p class="subTitle">{{$t('tour.Step')}} 1 ({{$t('tour.Creare New Campaign')}})</p>
        <div class="steps__image">
          <img
            src="@/assets/images/brandtour/step-1.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="button-row">
          <a
            @click="step = 2"
            href="javascript:void(0)"
            class="btn btn-primary full"
            >Next</a
          >
        </div>
      </div>
      <div v-if="step == 2">
        <div class="title">{{$t('tour.How to create your first campaign')}}!</div>
        <p class="subTitle">{{$t('tour.Step')}} 2 ({{$t('tour.Set the target and budget')}})</p>
        <div class="steps__image">
          <img
            src="@/assets/images/brandtour/step-2.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="inline-buttons">
          <a @click="step = 1" href="javascript:void(0)" class="btn btn-default"
            >Back</a
          >
          <a @click="step = 3" href="javascript:void(0)" class="btn btn-primary"
            >Next</a
          >
        </div>
      </div>
      <div v-if="step == 3">
        <div class="title">{{$t('tour.How to create your first campaign')}}!</div>
        <p class="subTitle">{{$t('tour.Step')}} 3 ({{$t('tour.Add freelance instructions')}})</p>
        <div class="steps__image">
          <img
            src="@/assets/images/brandtour/step-3.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="inline-buttons">
          <a @click="step = 2" href="javascript:void(0)" class="btn btn-default"
            >Back</a
          >
          <a href="javascript:void(0)" @click="step = 4" class="btn btn-primary"
            >Next</a
          >
        </div>
      </div>

      <!-- Step 4 modal -->
      <div v-if="step == 4">
        <div class="title">{{$t('tour.How to create your first campaign')}}!</div>
        <p class="subTitle">{{$t('tour.Step')}} 4 ({{$t('tour.Manage your active campaigns')}})</p>
        <div class="steps__image">
          <img
            src="@/assets/images/brandtour/step-4.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="button-row">
          <a
            href="javascript:void(0)"
            @click="$bvModal.hide('tour')"
            class="btn btn-primary full"
            >{{$t('tour.Finish')}}</a
          >
        </div>
      </div>
      <div class="hideEarningPopUp">
        <input type="checkbox" id="close_box" name="close_box" value="1" @click="hideEarningPopUp()">
        <label>{{$t('tour.Do not show again')}}.</label>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
    };
  },
  mounted() {
    if (this.$store.state.auth.showTour == 1 && this.getCookieValue() == 0) {
      this.$refs["tour"].show();
      this.$store.commit("UPDATE_TOUR", 0);
    }
  },
  methods: {
      hideEarningPopUp(){
        document.cookie = `brandPopup=1; expires=${new Date(new Date().getTime()+1000*60*60*24*365).toGMTString()}; path=/`;
        this.$refs["tour"].hide();
      },
      getCookieValue(){
        const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith('brand='))
        ?.split('=')[1] ?? 0;

        return cookieValue;
      }
  },
};
</script>
<style lang="scss" scoped>
#tour {
  &___BV_modal_body_{
    .hideEarningPopUp{
      text-align: center;
      margin-top: 30px;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      #close_box {      
      padding-right: 3rem;
    }
    label{
      margin-left:5px;
      font-weight:500;
      line-height:0px
    }
    }
    
  }
  
}
</style>
